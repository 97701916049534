import CompactLayout from 'src/layouts/compact/layout';
import { lazy, Suspense } from 'react';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { SplashScreen } from 'src/components/loading-screen';

const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
const PolicyPage = lazy(() => import('src/pages/policy'));
const Page500 = lazy(() => import('src/pages/error/500'));
const Page403 = lazy(() => import('src/pages/error/403'));
const Page404 = lazy(() => import('src/pages/error/404'));
const ContactPage = lazy(() => import('src/pages/contact-us'));

// ----------------------------------------------------------------------

const HomePage = lazy(() => import('src/pages/home'));

export function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <CompactLayout>
          <Suspense fallback={<SplashScreen />}>
            <HomePage />
          </Suspense>
        </CompactLayout>
      ),
    },
    {
      element: (
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      ),
      children: [
        { path: 'contact-us', element: <ContactPage /> },
        { path: 'policy', element: <PolicyPage /> },
      ],
    },

    // No match
    { path: '*', element: <Navigate to="/404" replace /> },
    {
      element: (
        <CompactLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </CompactLayout>
      ),
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
  ]);
}
